import React, { useMemo, useState } from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from 'react-i18next';
import { Booking, BookingStatus, ProductType } from '@travelity/api';
import { formatValue } from '@travelity/form';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Card, Heading, IconButton, IconButtonGroup, Tag } from '@travelity/ui';
import CircleIcon from '@mui/icons-material/Circle';
import OutboundIcon from '@mui/icons-material/Outbound';
import { EventStatus } from '@travelity/api/src/requests';
import { SingleLineKeyValue } from '../../../components/booking-participants/components/single-line-key-value';
import { MoveBookingModal } from '../../../components/move-booking-modal';
import { OverlayWithReason } from '../../../components/overlay-with-reason';
import { ProductTypeIcon } from '../../../components/product-type-icon';
import { BookingDate } from '../../../components/booking-date/booking-date';
import { useResourcePermissions } from '../../../contexts/user';

export interface BookingListItemProps {
    booking: Booking;
    isSelected: boolean;
    refetch: () => void;
}

export const BookingListItem = React.memo(
    ({ booking, isSelected, refetch }: BookingListItemProps) => {
        const { t } = useTranslation();

        const [moveBooking, setMoveBooking] = useState(false);
        const permissions = useResourcePermissions('booking');

        const options = useMemo(
            () =>
                booking.product.options?.map(({ name, pax }) => ({
                    name,
                    count: (Object.values(pax || {}) as number[]).reduce(
                        (s: number, c?: number) => s + (c || 0),
                        0
                    ),
                })),
            []
        );
        const upcoming =
            booking.eventStatus === EventStatus.UPCOMING ||
            booking.eventStatus === EventStatus.DRAFT;

        return (
            <Box>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{
                        mt: 2,
                        mb: 1,
                        pr: 5,
                        minWidth: 0,
                    }}
                >
                    <Stack
                        direction="row"
                        gap={1.5}
                        alignItems="center"
                        sx={{
                            minWidth: 0,
                        }}
                    >
                        <Heading
                            sx={{
                                fontSize: '14px',
                                fontWeight: '600',
                                pr: 1,
                            }}
                            alpha={0.7}
                            ellipsis
                        >
                            {booking.product.name}
                        </Heading>
                        <CircleIcon
                            sx={{ fontSize: '8px', color: '#D9D9D9' }}
                        />
                        <ProductTypeIcon
                            type={booking.product.type}
                            shared={booking.shared}
                        />
                        <Typography
                            sx={{
                                ml: '-8px',
                                color: '#6B748C',
                                fontSize: '12px',
                                fontWeight: '500',
                                whiteSpace: 'nowrap',
                                pr: 1.5,
                            }}
                        >
                            {booking.product.type === ProductType.TOUR
                                ? `${booking.shared ? 'Group' : 'Private'} `
                                : ''}
                            {t(booking.product.type, { ns: 'product' })}
                        </Typography>
                    </Stack>
                    <Stack direction="row" gap={1}>
                        <BookingDate date={booking.date} />
                    </Stack>
                </Stack>
                <Card
                    isSelected={isSelected}
                    buttons={
                        <IconButtonGroup>
                            <IconButton
                                href={`/bookings/${booking.id}`}
                                icon={<VisibilityIcon fontSize="small" />}
                                tooltip="Preview"
                            />
                            <Divider sx={{ mx: 0.75 }} />
                            <IconButton
                                href={`/order/${booking.orderId}/booking/${booking.id}`}
                                icon={<OutboundIcon fontSize="small" />}
                                tooltip="Go to Order"
                            />
                            {booking.status !== BookingStatus.CANCELLED &&
                                upcoming &&
                                permissions.update && (
                                    <>
                                        <Divider sx={{ mx: 0.75 }} />
                                        <IconButton
                                            onClick={() => {
                                                setMoveBooking(true);
                                            }}
                                            icon={
                                                <ExitToAppIcon fontSize="small" />
                                            }
                                            tooltip="Move"
                                        />
                                    </>
                                )}
                        </IconButtonGroup>
                    }
                >
                    <Stack gap={1} direction="row">
                        <Stack
                            sx={{
                                width: '82px',
                                py: 1,
                                px: 1,
                                borderRight: '1px solid #D6DAE4',
                            }}
                            gap={0.5}
                        >
                            <Stack
                                sx={{
                                    height: '50px',
                                }}
                                justifyContent="center"
                                alignItems="center"
                                gap={0.5}
                            >
                                <ConfirmationNumberIcon
                                    sx={{
                                        color:
                                            booking.status ===
                                            BookingStatus.CONFIRMED
                                                ? '#2CAC60'
                                                : '#D84F5F',
                                        fontSize: '24px',
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        color: '#B2B9CD',
                                    }}
                                >
                                    {t(booking.status, {
                                        ns: 'booking',
                                    })}
                                </Typography>
                            </Stack>
                            <Divider />
                            <Stack
                                sx={{
                                    height: '50px',
                                }}
                                justifyContent="center"
                                alignItems="center"
                                gap={0.5}
                            >
                                <MonetizationOnIcon
                                    sx={{
                                        color:
                                            booking.financials.debt === 0
                                                ? '#2CAC60'
                                                : '#D84F5F',
                                        fontSize: '24px',
                                    }}
                                />
                                <Heading
                                    sx={{
                                        fontSize: '12px',
                                        maxWidth: '100%',
                                    }}
                                    alpha={0.4}
                                    ellipsis
                                >
                                    {t(
                                        booking.financials.debt === 0
                                            ? 'paid'
                                            : 'not_paid',
                                        {
                                            ns: 'booking',
                                        }
                                    )}
                                </Heading>
                            </Stack>
                        </Stack>
                        <Stack sx={{ px: 2, py: 1 }} flexGrow={2}>
                            <SingleLineKeyValue
                                label="Customer"
                                darkValue
                                value={
                                    booking.participants?.customer.fullName ||
                                    ''
                                }
                            />
                            <Divider sx={{ marginTop: 0.5 }} />
                            <SingleLineKeyValue
                                label="PAX"
                                darkValue
                                value={`${formatValue(
                                    booking.participants?.pax || {}
                                )}`}
                            />
                            <Divider sx={{ marginTop: 0.5 }} />
                            <SingleLineKeyValue
                                label="Notes"
                                darkValue
                                value={`${booking.notes?.length || 0}`}
                            />
                        </Stack>
                    </Stack>
                    {!!options?.length && (
                        <>
                            <Divider />
                            <Stack
                                direction="row"
                                gap={0.5}
                                sx={{ px: 2, py: 1.5 }}
                            >
                                {options.map(option => (
                                    <Tag
                                        label={option.name}
                                        value={option.count}
                                    />
                                ))}
                            </Stack>
                        </>
                    )}
                    {booking.status === BookingStatus.CANCELLED && (
                        <OverlayWithReason
                            title="Cancelled"
                            reason={booking.reason}
                        />
                    )}
                </Card>
                <MoveBookingModal
                    handleCancel={() => setMoveBooking(false)}
                    handleConfirm={() => {
                        refetch();
                        setMoveBooking(false);
                    }}
                    booking={booking}
                    open={moveBooking}
                />
            </Box>
        );
    }
);
