import React, { useMemo, useState } from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import {
    productFilterToRequestBodyConverter,
    useProductsLazy,
} from '@travelity/api';
import { ProductItem } from '../../components/product-item';

import ProductEditForm from './components/product-edit-form';
import { ProductItemSkeleton } from '../../components/product-item-skeleton';
import {
    FilterOption,
    FilterTypes,
} from '../../components/filters/filters.types';
import { productTypeOptions } from '../select-type/select-type';
import { Filters } from '../../components/filters';
import {
    List,
    ListItems,
    ListMain,
    ListSidebar,
    ListTitle,
} from '../../components/list-layout';
import { useLoadOnScroll } from '../../hooks';
import { useUserContext } from '../../contexts/user';
import { useHasFilter } from '../../components/filters/filters.hooks';
import { AddButton } from '../../components/add-button';

const productFilters: FilterOption[] = [
    {
        name: 'productType',
        label: 'Product Type',
        type: FilterTypes.DROPDOWN,
        selectText: 'Please, select the value for product type',
        options: productTypeOptions,
    },
    {
        name: 'createdAt',
        label: 'Created At',
        type: FilterTypes.DATES,
        disable: used =>
            used.includes('updatedAt') ||
            used.includes('updatedBy') ||
            used.includes('deletedAt') ||
            used.includes('deletedBy'),
        selectText: 'Please, select the dates for created at filter',
    },
    {
        name: 'createdBy',
        label: 'Created By',
        type: FilterTypes.KEYWORD,
        disable: used =>
            used.includes('updatedAt') ||
            used.includes('updatedBy') ||
            used.includes('deletedAt') ||
            used.includes('deletedBy'),
        selectText: 'Type user name or email',
    },
    {
        name: 'updatedAt',
        label: 'Updated At',
        type: FilterTypes.DATES,
        disable: used =>
            used.includes('createdAt') ||
            used.includes('createdBy') ||
            used.includes('deletedAt') ||
            used.includes('deletedBy'),
        selectText: 'Please, select the dates for updated at filter',
    },
    {
        name: 'updatedBy',
        label: 'Updated By',
        type: FilterTypes.KEYWORD,
        disable: used =>
            used.includes('createdAt') ||
            used.includes('createdBy') ||
            used.includes('deletedAt') ||
            used.includes('deletedBy'),
        selectText: 'Type user name or email',
    },
    {
        name: 'deletedAt',
        label: 'Deleted At',
        type: FilterTypes.DATES,
        disable: used =>
            used.includes('createdAt') ||
            used.includes('createdBy') ||
            used.includes('updatedAt') ||
            used.includes('updatedBy'),
        selectText: 'Please, select the dates for deleted at filter',
    },
    {
        name: 'deletedBy',
        label: 'Deleted By',
        type: FilterTypes.KEYWORD,
        disable: used =>
            used.includes('createdAt') ||
            used.includes('createdBy') ||
            used.includes('updatedAt') ||
            used.includes('updatedBy'),
        selectText: 'Type user name or email',
    },
    {
        name: 'searchText',
        label: 'Search',
        type: FilterTypes.SEARCH,
        selectText: 'Search product names, route locations, etc...',
    },
];

export interface ProductsProps {}

function Products() {
    const navigate = useNavigate();
    const { productId } = useParams();

    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );

    // filters
    const [filters, setFilters] = useState({});
    const hasFilters = useHasFilter(filters, productFilters);
    const filterHeight = isMobile ? 0 : hasFilters ? 117 : 61;

    const {
        data: products,
        isLoading,
        refetch,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
    } = useProductsLazy(
        useMemo(() => productFilterToRequestBodyConverter(filters), [filters])
    );

    const product = useMemo(() => {
        return productId
            ? products?.find(({ id }) => id === productId)
            : undefined;
    }, [products, productId]);

    const { onScroll } = useLoadOnScroll({
        hasNextPage: !!hasNextPage && !isFetchingNextPage,
        fetchNextPage,
    });

    const { user } = useUserContext();
    const showCreate = user?.roleAccess.product?.create;

    return (
        <List>
            <ListMain
                isLoading={isLoading}
                SkeletonComponent={ProductItemSkeleton}
            >
                <ListTitle
                    title="All Products"
                    filters={
                        <Filters
                            values={filters}
                            setValues={setFilters}
                            options={productFilters}
                        />
                    }
                >
                    {showCreate && (
                        <AddButton
                            text="Create Product"
                            onClick={() => navigate('/products/add')}
                        />
                    )}
                </ListTitle>
                <ListItems
                    items={products?.length}
                    isLoading={isLoading}
                    scrollbarProps={{
                        onScrollY: onScroll,
                    }}
                    noItemsText="Filter results will be here"
                    entityName="product"
                    entityNamePlural="products"
                    subtractHeight={72 + filterHeight}
                >
                    {products?.map(p => (
                        <ProductItem
                            key={p.id}
                            product={p}
                            isSelected={productId === p.id}
                            refetch={refetch}
                        />
                    ))}
                    {hasNextPage && <ProductItemSkeleton />}
                </ListItems>
            </ListMain>
            <ListSidebar
                placeholderText="No product is selected for preview"
                isEmpty={!product}
                onClose={() => navigate('/products')}
            >
                {product && (
                    <ProductEditForm
                        product={product}
                        onClose={() => navigate('/products')}
                    />
                )}
            </ListSidebar>
        </List>
    );
}

export default Products;
