import React, { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import {
    memberToReferral,
    memberToStaff,
    teamDtoToTeam,
} from './team.converters';
import { MemberRole } from './team.types';
import {
    TeamsService,
    UsersService,
    CreateTeamReqDto,
    ListItemAction,
    PersonNameData,
    type GetTeamsResDto,
    type PhoneNumberData,
    type GetTeamsResItemDto,
    type UpdateMembersTeamsResDto,
} from '../../requests';
import { CustomMutationOptions, CustomQueryOptions } from '../common.types';

const useDebouncedStaffKey = 'useDebouncedStaffKey';
const useDebouncedReferralKey = 'useDebouncedReferralKey';
const useTeamsKey = 'useTeamsKey';

interface Filter {
    productId?: string;
    email?: string;
    name?: string;
}

export const useTeams = (
    options: CustomQueryOptions<ReturnType<typeof TeamsService.getTeams>> = {}
) => {
    const { getAccessTokenSilently } = useAuth0();

    const { data, ...other } = useQuery({
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;

            return TeamsService.getTeams(authorization);
        },
        queryKey: [useTeamsKey],
        ...options,
    });

    return {
        data: data?.items?.map(teamDtoToTeam),
        ...other,
    };
};

const mergeItems = (
    item: GetTeamsResItemDto,
    newItem: UpdateMembersTeamsResDto
): GetTeamsResItemDto =>
    ({
        ...item,
        ...newItem,
    } as GetTeamsResItemDto);

export const useTeamCache = () => {
    const queryClient = useQueryClient();

    const update = (id: string, newItem: UpdateMembersTeamsResDto) => {
        queryClient
            .getQueriesData({
                queryKey: [useTeamsKey],
                exact: false,
            })
            .forEach(([queryKey]) => {
                queryClient.setQueryData<GetTeamsResDto>(queryKey, data =>
                    !data?.items
                        ? undefined
                        : {
                              ...data,
                              items: data.items.map(item =>
                                  item.id === id
                                      ? mergeItems(item, newItem)
                                      : item
                              ),
                          }
                );
            });
        queryClient
            .getQueriesData({
                queryKey: [useDebouncedStaffKey],
                exact: false,
            })
            .forEach(([queryKey]) => {
                queryClient.setQueryData<GetTeamsResDto>(queryKey, data =>
                    !data?.items
                        ? undefined
                        : {
                              ...data,
                              items: data.items.map(item =>
                                  item.id === id
                                      ? mergeItems(item, newItem)
                                      : item
                              ),
                          }
                );
            });
        queryClient
            .getQueriesData({
                queryKey: [useDebouncedReferralKey],
                exact: false,
            })
            .forEach(([queryKey]) => {
                queryClient.setQueryData<GetTeamsResDto>(queryKey, data =>
                    !data?.items
                        ? undefined
                        : {
                              ...data,
                              items: data.items.map(item =>
                                  item.id === id
                                      ? mergeItems(item, newItem)
                                      : item
                              ),
                          }
                );
            });
    };

    return {
        update,
    };
};

export const useCreateTeam = (
    options: CustomMutationOptions<
        CreateTeamReqDto,
        ReturnType<typeof TeamsService.createTeam>
    > = {}
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation({
        mutationFn: async data => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return TeamsService.createTeam(authorization, data);
        },
        ...options,
    });
};

export const useInviteTeamMember = (
    options: CustomMutationOptions<
        {
            teamId: string;
            membership: MemberRole;
            email: string;
            company?: string;
        },
        ReturnType<typeof TeamsService.updateMembersTeams>
    > = {}
) => {
    const { update } = useTeamCache();
    const { getAccessTokenSilently } = useAuth0();

    return useMutation({
        mutationFn: async ({ teamId, membership, email, company }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return TeamsService.updateMembersTeams(teamId, authorization, {
                items: [
                    {
                        reference: {
                            contact_details: {
                                emails: [email],
                            },
                            organization: company
                                ? {
                                      name: company,
                                  }
                                : undefined,
                        },
                        membership: {
                            role: { type: membership },
                            action: ListItemAction.ADD,
                        },
                        action: ListItemAction.ADD,
                    },
                ],
            });
        },
        ...options,
        onSuccess: (...args) => {
            if (args[0]) update(args[1].teamId, args[0]);
            options?.onSuccess?.(...args);
        },
    });
};

export const useAcceptTeamInvitation = (
    options: CustomMutationOptions<
        {
            key: string;
        },
        ReturnType<typeof UsersService.acceptInvitationUsers>
    > = {}
) => {
    const { getAccessTokenSilently } = useAuth0();

    return useMutation({
        mutationFn: async ({ key }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return UsersService.acceptInvitationUsers(key, authorization);
        },
        ...options,
    });
};

export const useRemoveTeamMember = (
    options: CustomMutationOptions<
        {
            teamId: string;
            id: string;
        },
        ReturnType<typeof TeamsService.updateMembersTeams>
    > = {}
) => {
    const { update } = useTeamCache();
    const { getAccessTokenSilently } = useAuth0();

    return useMutation({
        mutationFn: async ({ teamId, id }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return TeamsService.updateMembersTeams(teamId, authorization, {
                items: [
                    {
                        id,
                        action: ListItemAction.REMOVE,
                    },
                ],
            });
        },
        ...options,
        onSuccess: (...args) => {
            if (args[0]) update(args[1].teamId, args[0]);
            options?.onSuccess?.(...args);
        },
    });
};

export const useUpdateTeamMember = (
    options: CustomMutationOptions<
        {
            teamId: string;
            id: string;
            name?: PersonNameData;
            phone_numbers: PhoneNumberData[];
            company?: string;
        },
        ReturnType<typeof TeamsService.updateMembersTeams>
    > = {}
) => {
    const { update } = useTeamCache();
    const { getAccessTokenSilently } = useAuth0();

    return useMutation({
        mutationFn: async ({ teamId, id, name, phone_numbers, company }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return TeamsService.updateMembersTeams(teamId, authorization, {
                items: [
                    {
                        id,
                        reference: {
                            contact_details: {
                                phone_numbers,
                            },
                            name,
                            organization:
                                company !== undefined
                                    ? {
                                          name: company,
                                      }
                                    : undefined,
                        },
                        action: ListItemAction.UPDATE,
                    },
                ],
            });
        },
        ...options,
        onSuccess: (...args) => {
            if (args[0]) update(args[1].teamId, args[0]);
            options?.onSuccess?.(...args);
        },
    });
};

export const useDebouncedStaff = (
    search: string,
    productId: string,
    debounce: number = 1000
) => {
    const [debouncedParams, setDebouncedParams] = React.useState<
        Filter | undefined
    >();

    React.useEffect(() => {
        const handler = setTimeout(() => {
            const filter: Filter = {};

            if (search.match(/^[^@]+@[^@.]+\.[a-zA-Z]+$/))
                filter.email = search;
            else if (search.length > 2) {
                filter.name = search;
            }
            setDebouncedParams(
                Object.keys(filter).length
                    ? { productId, ...filter }
                    : undefined
            );
        }, debounce);

        return () => {
            clearTimeout(handler);
        };
    }, [search, debounce]);

    const { getAccessTokenSilently } = useAuth0();

    const { data, ...other } = useQuery({
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            const params = debouncedParams as Filter;

            return TeamsService.getTeams1(authorization, {
                product_id: params.productId as string,
                email: params.email,
                name: params.name,
            });
        },
        queryKey: [useDebouncedStaffKey, debouncedParams],
        keepPreviousData: true,
        staleTime: debounce,
        enabled: !!debouncedParams,
    });

    const parsedData = useMemo(
        () =>
            debouncedParams && data?.items
                ? data.items.map(memberToStaff)
                : undefined,
        [data]
    );

    return {
        data: parsedData,
        ...other,
        isLoading: other.isFetching,
    };
};

interface ReferralFilter {
    email?: string;
    name?: string;
    productId?: string;
}

export const useDebouncedReferral = (
    search: string,
    productId: string,
    debounce: number = 1000
) => {
    const [debouncedParams, setDebouncedParams] = React.useState<
        ReferralFilter | undefined
    >();

    React.useEffect(() => {
        const handler = setTimeout(() => {
            const filter: ReferralFilter = {};

            if (search.match(/^[^@]+@[^@.]+\.[a-zA-Z]+$/))
                filter.email = search;
            else if (search.length > 2) {
                filter.name = search;
            }
            setDebouncedParams(
                Object.keys(filter).length
                    ? { productId, ...filter }
                    : undefined
            );
        }, debounce);

        return () => {
            clearTimeout(handler);
        };
    }, [search, debounce]);

    const { getAccessTokenSilently } = useAuth0();

    const { data, ...other } = useQuery({
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            const params = debouncedParams as ReferralFilter;

            return TeamsService.getTeams1(authorization, {
                product_id: params.productId as string,
                email: params.email,
                name: params.name,
            });
        },
        queryKey: [useDebouncedReferralKey, debouncedParams],
        keepPreviousData: true,
        staleTime: debounce,
        enabled: !!debouncedParams,
    });

    const parsedData = useMemo(
        () =>
            debouncedParams && data?.items
                ? data.items.map(memberToReferral)
                : undefined,
        [data]
    );

    return {
        data: parsedData,
        ...other,
        isLoading: other.isFetching,
    };
};

export const useRemoveTeam = (
    options?: CustomMutationOptions<{ teamId: string; reason?: string }, null>
) => {
    const { getAccessTokenSilently } = useAuth0();

    return useMutation({
        mutationFn: async ({ teamId, reason }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            console.log(
                'No endpoint to delete a team.',
                teamId,
                reason,
                authorization
            );
            return null;
        },
        ...options,
    });
};
